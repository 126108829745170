










































import Vue from 'vue';
import "../dialogs/ChangePassword.vue";
import {Backend} from "api-frontend";
import {User} from 'mp-common/src/types/entities/user';

export default Vue.extend({
  name: 'Profile',
  props: {
    user: Object as () => User,
    backend: Backend
  },
  data() {
    return {
      showSnackbar: false,
      snackBarContent: "",
      showChangePasswordDialog: false
    }
  },
  methods: {
    passwordChanged() {
      this.showChangePasswordDialog = false;
      this.snackBarContent = "Password changed successfully!";
      this.showSnackbar = true;
    },
  }
})
